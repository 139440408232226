import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Actions from '../store/actions'
import { useQuery } from "@apollo/client";
import { LOAD_NEXT_QUESTION } from "../graphQL/queries";

const SubQuestions = (props: any) => {
    const { subNode, node } = props;
    const dispatch = useDispatch();
    const { data: initData } = useQuery(LOAD_NEXT_QUESTION, {
        variables: { id: subNode.id }
    });
    const updateDOM = async (initData: any) => {
        if (initData) {
            console.log("get Sub", subNode);
            // @ts-ignore
            if (initData?.queryTravelQuestion.length >= 1) {
                // @ts-ignore
                await dispatch(Actions.appendSubQuestion(initData?.queryTravelQuestion[0], node))
            }
        }
    }
    useEffect(() => {
        updateDOM(initData);
    }, [initData]);
    return <div>
    </div>

}
export default SubQuestions;