import React, { useEffect } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from } from '@apollo/client'
// import logo from './logo.svg';
import './App.css';
import { env } from "../package.json";
import { onError } from '@apollo/client/link/error';
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import MainPage from './components/mainPage';
import thunk from "redux-thunk";
import questionReducer from './store/reducer/questions.reducer';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'rsuite/dist/rsuite.min.css'; // or 'rsuite/dist/rsuite.min.css'
import 'react-toastify/dist/ReactToastify.css';
import setupLogRocketReact from 'logrocket-react';
import LogRocket from 'logrocket';
import * as Actions from './store/actions';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.error("graphQLErrors", message, locations, path)
      return true;
    })
  }
})
let ENV = "https://white-sunset.us-east-1.aws.cloud.dgraph.io/graphql";

if (env === 'development') {
  ENV = "https://blue-surf-590149.us-east-1.aws.cloud.dgraph.io/graphql";
} else {
  console.warn = console.log = console.error = () => { };
}
const link = from([
  errorLink,
  new HttpLink({ uri: ENV })
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link
});

//test
const rootReducer = combineReducers({
  Question: questionReducer,
});
const store = createStore(rootReducer, applyMiddleware(thunk));
const query = new URLSearchParams(window.location.search);

store.dispatch(Actions.setRef(query.get("ref"))); // add ref

function App() {
  LogRocket.init('yfnsng/travelsment');
  LogRocket.identify('Plan My Trip', {
    name: 'Guest User',
    // Add your own custom user variables here, ie:
    subscriptionType: 'pro'
  });
  setupLogRocketReact(LogRocket);
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <MainPage />
      </ApolloProvider>
    </Provider>
  );
}

export default App;
