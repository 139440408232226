import { log } from "console";
import _ from "lodash";

export const SET_QUESTIONS: string = 'SET_QUESTIONS';
export const UPDATE_SUMMARY: string = 'UPDATE_SUMMARY';
export const LOCATION: string = 'LOCATION';
export const REF: string = 'REF';

export function setRef(ref: string | null) {
    return {
        type: REF,
        ref
    };
}

export function setQuestions(Questions: any) {
    // console.log("Questions", Questions);
    return {
        type: SET_QUESTIONS,
        Questions
    };
}
export function setDesitnationThroughParams(ParamLocation: any) {
    return {
        type: LOCATION,
        ParamLocation
    };
}
export function setSummary(Summary: any) {
    console.log("SummarySummary", Summary);
    return {
        type: UPDATE_SUMMARY,
        Summary
    };
}
export function findLocation() {
    return async function (dispatch: any, getState: any) {
        const query = window.location.search.split('&').filter((f => f.startsWith("ref="))).join('&'); // ignore ref
        var loc = query.split('=')[2];
        if (loc) {
            dispatch(setDesitnationThroughParams(loc));
        }

        // let urlString = window.location.href;
        // let paramString = urlString.split('?')[1];
        // let queryString: any = new URLSearchParams(paramString);
        // console.log("urlString", urlString);
        // for (let pair of queryString.entries()) {
        //     if (pair[0] === 'loc') {
        //         dispatch(setDesitnationThroughParams(pair[1]));
        //     }
        // }
    };
}

export function appendSubQuestion(subNode: any, node: any) {
    return async function (dispatch: any, getState: any) {
        let QUE = JSON.parse(JSON.stringify([...getState().Question.Questions]));
        let index = QUE.findIndex((data: { id: any; }) => data.id === node.id);

        if (index !== -1) {
            if (!QUE[index].subQueList) {
                QUE[index].subQueList = []
            }
            let SubIndex = QUE[index].subQueList.findIndex((data: { id: any; }) => data.id === QUE[index].id);
            console.log("QUE[index].subQueList", QUE[index].subQueList);
            if (SubIndex === -1) {
                QUE[index].subQueList.push(subNode);
            }
            if (QUE[index].subQueList.length === QUE[index].subQuestions.length) {
                QUE[index].loadSubSet = false;
            }
        }
        dispatch(setQuestions(QUE));
    }
};
export function appendQuestion(Question: any, node: any, HideFlag = true) {
    return async function (dispatch: any, getState: any) {
        let QUE = JSON.parse(JSON.stringify([...getState().Question.Questions]));
        if (QUE.findIndex((data: { id: any; }) => data.id === node.id) !== -1) {
            QUE[QUE.findIndex((data: { id: any; }) => data.id === node.id)].status = true;
        }
        let Data: any = JSON.parse(JSON.stringify(Question[0]));
        Data.selectedAns = '';
        Data.hide = HideFlag;
        Data.groupedList = _.groupBy(Data.answerChoices, 'displayRow');
        console.log("catscatscatscats", Data.groupedList);
        if (Data.type !== 'Cruise' && Data.key === "destination") {
            Data.selectedAns = getState().Question.ParamLocation;
            let Summary = getState().Question.Summary;
            console.log("Summary1", Summary);
            Summary.destination.value = Data.selectedAns;
            console.log("vvvvalue", Summary.destination.value)

            dispatch(setSummary(Summary));
        }
        console.log("abcd", Data);

        if (Data.type === 'Cruise' && Data.key === "cruiseDestinations") {
            const Location = getState().Question.ParamLocation
            console.log("aaroomaaalll1", Data.selectedAns);

            if (Location.length === 0) {
                Data.selectedAns = []

            }
            else {
                var temp = Data.answerChoices.filter((data: { value: string }) => data.value.toLowerCase().includes(Location.toLowerCase()))
                const data = temp.map((d: any) => {
                    Data.selectedAns = [...Data.selectedAns, d.value,]
                })
                if (Location === 'Caribbean') {
                    Data.selectedAns = [...Data.selectedAns, 'Bahamas']
                }
                if (temp.length === 0) {
                    Data.selectedAns = [Location, 'Any Destination']
                }
            }

            let Summary = getState().Question.Summary;
            console.log("Summary1", Summary);
            console.log("aaroomaaalll1", Data.selectedAns);

            // Summary.cruiseDestinations.value=[...Data.selectedAns]

            dispatch(setSummary(Summary));
        }
        console.log("aaroomaaalll2", Data.selectedAns);

        if (Data.key === "tripDateRange") {
            var endDate = new Date();
            endDate.setDate(endDate.getDate() + 4);
            Data.selectedAns = {
                startDate: endDate,
                endDate: endDate,
                key: 'selection',
            };

        }
        if (Data.defaultValue) {
            Data.selectedAns = [Data.defaultValue];
        }
        if (Data.key === "bookingHelp") {
            Data.answerChoices.filter((bb: any) => {
                return bb.isAlwaysSelected === true;
            }).map((cc: any) => {
                return Data.selectedAns.push(cc.value)
            });
            // if(Data.defaultValue !== '' && Data.defaultValue !== null){
            //     Data.selectedAns.push(Data.defaultValue)
            // }
        }
        Data.loadSubSet = true;
        QUE.push(Data);
        dispatch(setQuestions(QUE));
    };
}
export function updateSelections(ans: any, node: any, parent: any) {
    return async function (dispatch: any, getState: any) {
        let QUE = JSON.parse(JSON.stringify([...getState().Question.Questions]));
        console.log("reeeeeeeeee", ans)
        if (parent.id) {
            // console.log("reeeeeeeeee", ans)
            const index = QUE.findIndex((data: { id: any; }) => data.id === parent.id);
            if (index !== -1) {
                // console.log("reeeeeeeeee", ans)
                // console.log("QUE[index].selectedAns", QUE[index].selectedAns)
                // if (!QUE[index].selectedAns || QUE[index]?.selectedAns === '') {
                //     QUE[index].selectedAns = ' ';
                // }
                const subIndex = QUE[index].subQueList.findIndex((data: { id: any; }) => data.id === node.id);
                if (!QUE[index].subQueList[subIndex].selectedAns) {
                    QUE[index].subQueList[subIndex].selectedAns = []
                }
                if (node.answerType === 'MultipleChoiceTextInput') {
                    // console.log("reeeeeeeeee", ans)
                    const len = QUE[index].answerChoices.findIndex((n: any) => n.value === ans);
                    if (len !== -1) {
                        const SIndex = QUE[index].subQueList[subIndex].selectedAns.findIndex((n: any) => n === ans);
                        if (SIndex === -1) {
                            QUE[index].subQueList[subIndex].selectedAns.push(ans);
                        } else {
                            QUE[index].subQueList[subIndex].selectedAns.splice(SIndex, 1);
                        }
                    } else {
                        // console.log("reeeeeeeeee", ans)
                        QUE[index].subQueList[subIndex].selectedAns2 = ans
                    }
                } else {
                    const SIndex = QUE[index].subQueList[subIndex].selectedAns.findIndex((n: any) => n === ans);
                    if (SIndex === -1) {
                        QUE[index].subQueList[subIndex].selectedAns.push(ans);
                    } else {
                        QUE[index].subQueList[subIndex].selectedAns.splice(SIndex, 1);
                    }
                }
                // MultipleChoiceTextInput
            }
            dispatch(setQuestions(QUE));
        } else {
            const index = QUE.findIndex((data: { id: any; }) => data.id === node.id);
            if (index !== -1) {
                if (!QUE[index].selectedAns) {
                    QUE[index].selectedAns = []
                }
                console.log("00000000", QUE);
                if (node.answerType === 'MultipleChoiceTextInput') {
                    const len = QUE[index].answerChoices.findIndex((n: any) => n.value === ans);
                    if (len !== -1) {
                        const SIndex = QUE[index].selectedAns.findIndex((n: any) => n === ans);
                        if (SIndex === -1) {
                            QUE[index].selectedAns.push(ans);
                        } else {
                            QUE[index].selectedAns.splice(SIndex, 1);
                        }
                    } else {
                        // console.log("reeeeeeeeee", ans)
                        QUE[index].selectedAns2 = ans
                    }
                } else {
                    if (QUE[index].key === "bookingHelp" && ans === "Accomodations") {
                        //“Accommodation” is not optional, the rest of labels are optional
                    } else {
                        const SIndex = QUE[index].selectedAns.findIndex((n: any) => n == ans);
                        console.log("QUE[index].selectedAns", JSON.stringify(QUE[index].selectedAns), ans, SIndex);
                        if (SIndex === -1) {
                            QUE[index].selectedAns.push(ans);
                        } else {
                            QUE[index].selectedAns.splice(SIndex, 1);
                        }
                        console.log("QUE[index].selectedAnssss", JSON.stringify(QUE[index].selectedAns), ans, SIndex);
                    }
                }
            }
            dispatch(setQuestions(QUE));
        }
    };
}
export function updateSingleSelections(ans: any, node: any, parent: any) {
    return async function (dispatch: any, getState: any) {
        let QUE = JSON.parse(JSON.stringify([...getState().Question.Questions]));
        // console.log("ANS UP - ", ans, node.id, parent?.id)
        if (parent.id) {
            const index = QUE.findIndex((data: { id: any; }) => data.id === parent.id);
            if (index !== -1) {
                const subIndex = QUE[index].subQueList.findIndex((data: { id: any; }) => data.id === node.id);
                if (index !== -1) {
                    QUE[index].subQueList[subIndex].selectedAns = [];
                    QUE[index].subQueList[subIndex].selectedAns.push(ans);
                }
            }
            dispatch(setQuestions(QUE));
        } else {
            const index = QUE.findIndex((data: { id: any; }) => data.id === node.id);
            if (index !== -1) {
                QUE[index].selectedAns = [];
                QUE[index].selectedAns.push(ans);
            }
            dispatch(setQuestions(QUE));
        }
    };
}
export function updateAir(key: any, flag: any) {
    return async function (dispatch: any, getState: any) {
        let QUE = JSON.parse(JSON.stringify([...getState().Question.Questions]));
        // console.log('QUEQUEQUEQUE', QUE)
        const index = QUE.findIndex((data: { key: any; }) => data.key === key);
        if (index !== -1) {
            QUE[index].hide = flag;
        }
        dispatch(setQuestions(QUE));
    }
}

export function updateValues(ans: any, node: any, parent: any, naming = '') {
    // console.log("updateValues", ans, node, parent)
    return async function (dispatch: any, getState: any) {
        let QUE = JSON.parse(JSON.stringify([...getState().Question.Questions]));
        // console.log("ANS UP - ", ans, node.id, parent?.id)
        if (parent.id) {
            const index = QUE.findIndex((data: { id: any; }) => data.id === parent.id);
            if (index !== -1) {
                const subIndex = QUE[index].subQueList.findIndex((data: { id: any; }) => data.id === node.id);
                if (index !== -1) {
                    QUE[index].subQueList[subIndex].selectedAns = ans;
                }
            }
            dispatch(setQuestions(QUE));
        } else {
            const index = QUE.findIndex((data: { id: any; }) => data.id === node.id);
            // console.log("indexindexindexindex",index);
            if (index !== -1) {
                if (node.answerType === 'Email') {
                    ans = ans.replace(/\s{1,}/g, '').replace(/[^A-Za-z0-9._@-]/g, "")
                } else if (node.key === 'previousHotels') {
                    ////
                } else if (node.answerType === 'TextInput' || node.answerType === 'FirstNameLastName' || node.answerType === 'TextAreaInput') {
                    ans = ans.replace(/\s{2,}/g, ' ').replace(/[&\/\\#+!()@$~%:*?<>{}]/g, "");
                }
                if (naming === 'first') {
                    QUE[index].selectedAns1 = ans;
                    QUE[index].selectedAns = (QUE[index].selectedAns1 || '') + ' ' + (QUE[index].selectedAns2 || '');
                } else if (naming === 'last') {
                    QUE[index].selectedAns2 = ans;
                    QUE[index].selectedAns = (QUE[index].selectedAns1 || '') + ' ' + (QUE[index].selectedAns2 || '');
                } else {
                    QUE[index].selectedAns = ans;
                }
            }
            dispatch(setQuestions(QUE));
        }
    };
}
export function updateSummary() {
    return async function (dispatch: any, getState: any) {
        let QUE = JSON.parse(JSON.stringify([...getState().Question.Questions]));
        let Summary = getState().Question.Summary;
        Object.keys(Summary).map(function (node) {
            const index = QUE.findIndex((data: { key: any; }) => data.key === Summary[node].key);
            if (index !== -1) {
                Summary[node].value = QUE[index].selectedAns;
                console.log("Test Node", node);
                if (Summary[node].key === "roomPreferences" && QUE[index]?.selectedAns2 && QUE[index]?.selectedAns2 !== '') {
                    Summary[node].value.push(QUE[index].selectedAns2)
                }
            }
            let parent = 0;
            let subIndex = -1;
            for (let i = 0; i <= QUE.length; i++) {
                if (QUE[i]?.subQueList) {
                    // console.log("%", QUE[i]?.subQueList, Summary[node])
                    subIndex = QUE[i].subQueList.findIndex((data: { key: any; }) => data.key === Summary[node].key);
                    if (subIndex !== -1) {
                        parent = i;
                        i = QUE.length + 1
                    }
                }
            }
            if (parent < QUE.length && subIndex != -1) {
                if (QUE[parent]?.subQueList[subIndex] && QUE[parent]?.subQueList[subIndex].key === Summary[node].key) {
                    Summary[node].value = QUE[parent].subQueList[subIndex]?.selectedAns;

                }
            }
        });
        dispatch(setSummary(Summary));
    };
}