import { useEffect, useState, useRef } from "react";
import TravelQuestions  from "../components/TravelQuestion";

const MainPage = (props: any) => {
    const [flagQue, setFlagQue] = useState(false)
    return (
        <TravelQuestions/>
        
    
    );
}

export default MainPage;